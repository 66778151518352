/*  ==========================================================================
    SLIDER
    Helper to generate slider elements like dots or arrows.

    INFO:
    - adjusted for slick slider
    ========================================================================== */

// arrows (f.e. vista slider)
@mixin slider--arrows($clr) {
  @include clearfix();
  .bn-arrows__arrow {
    color: rgba($clr, 0.5);
    cursor: pointer;
    float: left;
    font-size: 0;
    .bn-icon {
      font-size: 60px;
    }
    &.bn-arrows__arrow-next {
      float: right;
    }
    &:hover {
      color: $clr;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    .bn-arrows__arrow {
      .bn-icon {
        font-size: 40px;
      }
    }
  }
}

// arrows v2 (f.e. impressions slider)
@mixin slider--arrows-v2($clr,$clr-hover) {
  margin-bottom: 20px;
  text-align: right;
  .bn-arrows__arrow {
    color: $clr;
    cursor: pointer;
    display: inline-block;
    font-size: 3.5em;
    line-height: 1.0;
    &.bn-arrows__arrow-next {
      margin-left: 15px;
    }
    &:hover {
      color: $clr-hover;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    margin-bottom: 10px;
    .bn-arrows__arrow {
      font-size: 3.0em;
      &.bn-arrows__arrow-next {
        margin-left: 10px;
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    margin-bottom: 5px;
    .bn-arrows__arrow {
      font-size: 2.5em;
      &.bn-arrows__arrow-next {
        margin-left: 5px;
      }
    }
  }
}

// dots
@mixin slider--dots($bg-clr,$anim-dur) {
  ul {
    @include clearfix();
    display: inline-block;
    list-style-type: none;
    padding-left: 0;
    li {
      background-color: rgba($bg-clr, 0.5);
      cursor: pointer;
      float: left;
      height: 5px;
      margin: 5px 10px 0 0;
      position: relative;
      width: 40px;
      button {
        @include button--reset();
        background-color: $bg-clr;
        cursor: default;
        height: 100%;
        left: 0;
        max-width: 0;
        overflow: hidden;
        position: absolute;
        text-indent: -100px;
        top: 0;
        width: 100%;
        z-index: $unit__zi--mixins-slider-dots-button;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: $bg-clr;
      }
      &.slick-active {
        cursor: default;
        button {
          animation-direction: alternate;
          // auto play speed of slick slider
          animation-duration: #{$anim-dur}s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-name: stretch;
          max-width: 100%;
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // laptop
  @media #{$mq__laptop} {
    ul {
      li {
        width: 30px;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    ul {
      li {
        width: 20px;
      }
    }
  }
}