/*  ==========================================================================
    OFFERS LIST VIEW
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__offers-list-view--arrows: $clr__ci--quaternary;
$module-clr__offers-list-view--arrows-hover: rgba($clr__ci--quaternary, 0.7);
$module-clr__offers-list-view--bg-primary: $clr__ci--primary;
$module-clr__offers-list-view--bg-secondary: $clr__ci--secondary;
$module-clr__offers-list-view--bg-tertiary: $clr__ci--tertiary;
$module-clr__offers-list-view--filter: $clr__ci--primary;
$module-clr__offers-list-view--filter-accent: $clr__ci--secondary;
$module-clr__offers-list-view--typo-primary: $clr__typo--secondary;
$module-clr__offers-list-view--typo-secondary: $clr__typo--secondary;
$module-clr__offers-list-view--typo-tertiary: $clr__typo--primary;

/*
 * MODULE
 */

.bn-offers-list-view {
  .bn-offers-filter {
    @include offers--filter($module-clr__offers-list-view--filter,$module-clr__offers-list-view--filter-accent);
  }
  .bn-offers-list-view__arrows {
    @include slider--arrows-v2($module-clr__offers-list-view--arrows,$module-clr__offers-list-view--arrows-hover);
  }
  .bn-offers-list-view__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-offer-box {
      border: 1px solid $clr__ci--quaternary;
      background-color: $module-clr__offers-list-view--bg-primary;
      color: $module-clr__offers-list-view--typo-primary;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
      width: calc(50% - 10px);
      a {
        color: $module-clr__offers-list-view--typo-primary;
        &:visited {
          color: $module-clr__offers-list-view--typo-primary;
        }
        &:hover {
          color: $module-clr__offers-list-view--typo-primary;
        }
      }
      .bn-offer-box__top {
        //box-shadow: 0px 3px 6px rgba(#000000, 0.4);
        @include aspect-ratio(4,3);
        display: block;
        overflow: hidden;
        position: relative;
        .bn-offer-box__image {
          background-color: $clr__black;
          height: 100%;
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
          img {
            @include img--responsive();
          }
        }
        .bn-offer-box__price {
          background-color: rgba($clr__ci--quaternary, 0.8);
          bottom: 20px;
          height: 150px;
          overflow: hidden;
          position: absolute;
          right: 20px;
          min-width: 150px;
          max-width: 150px;
          z-index: 5;
          * {
            color: $clr__ci--primary;
          }
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-offer-box__amount-addition {
            font-size: 1.6em;
          }
          .bn-offer-box__amount {
            font-size: 2.5em;
            font-weight: $typo__fw--bold;
            font-family: $typo__ff--secondary;
          }
        }
      }
      .bn-offer-box__bottom {
        padding: 40px;
        .bn-offer-box__title {
          @include typo--subheadline($module-clr__offers-list-view--typo-primary);
        }
        .bn-offer-box__seasondatas,
        .bn-offer-box__price {
          display: flex;
          > div {
            font-size: $typo__fs--text;
            .bn-offer-box__nights {
              display: inline-block;
              margin-top: 10px;
            }
            &:first-child {
              font-size: calc(#{$typo__fs--text} + 0.2em);
              margin-right: 10px;
            }
          }
        }
        .bn-offer-box__seasondatas {
          margin-top: 20px;
          > div {
            font-weight: $typo__fw--light;
          }
        }
        .bn-offer-box__price {
          font-weight: $typo__fw--light;
          margin-top: 20px;
          .bn-offer-box__amount {
            font-weight: $typo__fw--bold;
          }
        }
        .bn-offer-box__button {
          margin-top: 20px;
          .bn-button {
            @include button--cta();
            background-color: transparent;
            border: 1px solid $clr__ci--quaternary;
            color: #ffffff;
            height: 40px;
            line-height: 39px;
            &:hover {
              border: 1px solid $clr__ci--quaternary;
              color: #ffffff !important;
              background-color: rgba($clr__ci--quaternary, 0.5);
            }
          }
        }
      }
      // recolor every second and third box
      &.bn-offer-box--secondary {
        background-color: $module-clr__offers-list-view--bg-secondary;
        color: $module-clr__offers-list-view--typo-secondary;
        a {
          color: $module-clr__offers-list-view--typo-secondary;
          &:visited {
            color: $module-clr__offers-list-view--typo-secondary;
          }
          &:hover {
            color: $module-clr__offers-list-view--typo-secondary;
          }
        }
        .bn-offer-box__top {
          .bn-offer-box__price {
            background-color: rgba($clr__ci--quaternary, 0.8);
            .bn-offer-box__amount {
              font-size: 2.5em;
              font-weight: $typo__fw--bold;
              font-family: $typo__ff--secondary;
            }
          }
        }
        .bn-offer-box__bottom {
          .bn-offer-box__title {
            @include typo--subheadline($module-clr__offers-list-view--bg-secondary);
          }
        }
      }
      &.bn-offer-box--tertiary {
        background-color: $module-clr__offers-list-view--bg-tertiary;
        color: $module-clr__offers-list-view--typo-tertiary;
        a {
          color: $module-clr__offers-list-view--typo-tertiary;
          &:visited {
            color: $module-clr__offers-list-view--typo-tertiary;
          }
          &:hover {
            color: $module-clr__offers-list-view--typo-tertiary;
          }
        }
        .bn-offer-box__top {
          .bn-offer-box__price {
            background-color: rgba($clr__ci--quaternary, 0.8);
            .bn-offer-box__amount {
              font-size: 2.5em;
              font-weight: $typo__fw--bold;
              font-family: $typo__ff--secondary;
            }
          }
        }
        .bn-offer-box__bottom {
          .bn-offer-box__title {
            @include typo--subheadline($module-clr__offers-list-view--typo-secondary);
          }
        }
      }
      // reposition image on every second box
      &.bn-offer-box--even {
        .bn-offer-box__top {
          order: 2;
          .bn-offer-box__price {
            top: auto;
            right: 20px;
            left: auto;
            bottom: 20px;
          }
        }
        .bn-offer-box__bottom {
          order: 1;
        }
      }
      // remove margin top on first and second box
      &:nth-child(-n+2) {
        margin-top: 0;
      }
    }
    .bn-pagination {
      margin-top: 40px;
      text-align: center;
      width: 100%;
      .bn-button {
        @include button--default();
      }
    }
    // enlarged
    &.bn-offers-list-view__holder--enlarged {
      .bn-offer-box {
        width: calc(33.33% - 10px);
        // remove margin top on first, second and third box
        &:nth-child(-n+3) {
          margin-top: 0;
        }
      }
    }
    // slidable
    &.bn-offers-list-view__holder--slidable {
      &.slick-slider {
        margin-left: -10px;
        width: calc(100% + 20px);
        .slick-slide {
          > div {
            height: 100%;
            .bn-offer-box {
              display: flex !important;
              height: 100%;
              margin: 0 10px !important;
              width: calc(100% - 20px) !important;
            }
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-offers-list-view .bn-offers-list-view__holder .bn-offer-box .bn-offer-box__top .bn-offer-box__price {
    max-width: initial;
  }
  .bn-offers-list-view {
    .bn-offers-list-view__holder {
      .bn-offer-box {
        .bn-offer-box__top {
          .bn-offer-box__price {
            height: 125px;
            min-width: 125px;
          }
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-offers-list-view {
    .bn-offers-list-view__holder {
      .bn-offer-box {
        .bn-offer-box__bottom {
          padding: 20px;
          .bn-offer-box__seasondatas {
            margin-top: 0px;
          }
          .bn-offer-box__price {
            margin-top: 10px;
          }
          .bn-offer-box__button {
            .bn-button {
              display: block;
              min-width: 0;
              width: 100%;
            }
          }
        }
      }
      // enlarged
      &.bn-offers-list-view__holder--enlarged {
        .bn-offer-box {
          width: calc(50% - 10px);
          &:nth-child(3) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-offers-list-view {
    .bn-offers-list-view__holder {
      display: block;
      .bn-offer-box {
        margin-top: 20px !important;
        width: 100%;
        .bn-offer-box__top {
          display: flex;
          flex-direction: column;
          height: auto;
          padding-top: 0;
          .bn-offer-box__image {
            height: auto;
            left: auto;
            order: 2;
            position: static;
            top: auto;
          }
          .bn-offer-box__price {
            bottom: auto;
            height: auto;
            left: auto !important;
            min-width: 0;
            order: 1;
            position: static;
            right: auto;
            top: auto !important;
            > div {
              display: block;
              height: auto;
            }
            .bn-offer-box__amount-addition,
            .bn-offer-box__amount {
              display: inline-block;
            }
            .bn-offer-box__amount-addition {
              margin: 0 5px;
            }
          }
        }
        .bn-offer-box__bottom {
          .bn-offer-box__price {
            display: none;
          }
        }
        // undo repositioning of image and text
        &.bn-offer-box--even {
          .bn-offer-box__top {
            order: 1;
          }
          .bn-offer-box__bottom {
            order: 2;
          }
        }
        // remove margin top on first box
        &:first-child {
          margin-top: 0 !important;
        }
      }
      // enlarged
      &.bn-offers-list-view__holder--enlarged {
        .bn-offer-box {
          width: 100%;
        }
      }
      // slidable
      &.bn-offers-list-view__holder--slidable {
        &.slick-slider {
          margin-left: 0;
          width: 100%;
          .slick-slide {
            > div {
              .bn-offer-box {
                margin: 0 !important;
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-offers-list-view {
    .bn-offers-list-view__holder {
      .bn-pagination {
        margin-top: 20px;
      }
    }
  }
}